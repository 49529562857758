import { useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'
import {
  Filter,
  GET_LIST,
  ListProps,
  sanitizeFetchType,
  useListContext,
  useQueryWithStore,
  useRedirect,
} from 'react-admin'
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Inject,
  ResourceDirective,
  ResourcesDirective,
  TimelineMonth,
} from '@syncfusion/ej2-react-schedule'
import { Authority } from '../../core/auth/Authority'
import { ResourceName } from '../../core/ResourceName'
import List from '../common/customized-ra-components/List'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import FilterProps from '../common/FilterProps'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import { VacationCalendarDto } from '../../core/dto/vacation-calendar.dto'
import {
  DateTime,
  getFullMonthRangeFromDate,
  ignoreTimezoneOffset,
} from '../../core/common/date-time'

const VacationCalendarFilters = (props: FilterProps) => {
  const hasAuthority = useHasAuthority()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Filter {...props}>
      {hasAuthority(Authority.VIEW_DOCTORS) && (
        <FilteredReferenceInput
          label="resources.vacation-calendars.fields.mainDoctorId"
          source="mainDoctorId"
          reference={ResourceName.DOCTORS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="fullNameSearch"
          filter={{
            doctorsOnly: true,
          }}
          selectWithPaginationInputProps={{
            optionText: (record: DoctorDto) =>
              `${record.firstName} ${record.lastName}`,
            showFilter: true,
          }}
        />
      )}
      {hasAuthority(Authority.VIEW_DOCTORS) && (
        <FilteredReferenceInput
          label="resources.vacation-calendars.fields.temporaryDoctorId"
          source="temporaryDoctorId"
          reference={ResourceName.DOCTORS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          filterSource="fullNameSearch"
          filter={{
            doctorsOnly: true,
          }}
          selectWithPaginationInputProps={{
            optionText: (record: DoctorDto) =>
              `${record.firstName} ${record.lastName}`,
            showFilter: true,
          }}
        />
      )}
    </Filter>
  )
}

// const VacationCalendarGrid = ({ ...props }) => (
//   <Datagrid {...props} size="small" rowClick="show" hasBulkActions={false}>
//     <TextField source="id" label="ID" />
//     <ReferenceField
//       source="mainDoctorId"
//       reference={ResourceName.DOCTORS}
//       link={false}
//       emptyText="-"
//     >
//       <FunctionField<DoctorDto>
//         source="mainDoctorId"
//         render={(record?: DoctorDto) => (
//           <Typography variant="body2">
//             {record?.firstName} {record?.lastName}
//           </Typography>
//         )}
//       />
//     </ReferenceField>
//     <ReferenceField
//       source="temporaryDoctorId"
//       reference={ResourceName.DOCTORS}
//       link={false}
//       emptyText="-"
//     >
//       <FunctionField<DoctorDto>
//         source="temporaryDoctorId"
//         render={(record?: DoctorDto) => (
//           <Typography variant="body2">
//             {record?.firstName} {record?.lastName}
//           </Typography>
//         )}
//       />
//     </ReferenceField>
//     <DateField
//       source="dateFrom"
//       showTime
//       options={{
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric',
//       }}
//       emptyText="-"
//     />
//     <DateField
//       source="dateTo"
//       showTime
//       options={{
//         day: '2-digit',
//         month: '2-digit',
//         year: 'numeric',
//       }}
//       emptyText="-"
//     />
//   </Datagrid>
// )

const CustomMonthView = () => {
  const { data, ids, loading, setFilters, filterValues, basePath } =
    useListContext()
  const redirect = useRedirect()

  const { data: doctors, loaded: doctorsLoaded } = useQueryWithStore({
    type: sanitizeFetchType(GET_LIST),
    resource: ResourceName.DOCTORS,
    payload: {
      filter: { doctorsOnly: true },
      sort: { field: 'lastName', order: 'ASC' },
    },
  })

  const getScheduleData = doctorsLoaded
    ? ids.map((id) => {
        const mainDoctor = doctors.find(
          (d: DoctorDto) =>
            d.id === ((data[id] as VacationCalendarDto).mainDoctorId as number),
        ) as DoctorDto
        const tempDoctor = doctors.find(
          (d: DoctorDto) =>
            d.id ===
            ((data[id] as VacationCalendarDto).temporaryDoctorId as number),
        ) as DoctorDto
        return {
          Subject: `${tempDoctor?.firstName} ${tempDoctor?.lastName}`,
          StartTime: (data[id] as VacationCalendarDto).dateFrom,
          EndTime: (data[id] as VacationCalendarDto).dateTo,
          DoctorId: mainDoctor.id,
          Id: id,
        }
      })
    : []

  const doctorsResource = doctorsLoaded
    ? doctors.map((d: DoctorDto) => ({
        text: `${d.firstName} ${d.lastName}`,
        id: d.id,
      }))
    : []

  const setDateRange = (dateFrom: DateTime, dateTo: DateTime) => {
    setFilters({ date: `${dateFrom};${dateTo}` }, false)
  }

  const handleNavigating = (e: any) => {
    const { dateFrom, dateTo } = getFullMonthRangeFromDate(e.currentDate)
    setDateRange(
      dateFrom.toISOString() as DateTime,
      dateTo.toISOString() as DateTime,
    )
  }

  const handleCellClick = (e: any) => {
    e.cancel = true
    const dateFrom = ignoreTimezoneOffset(e.startTime).toISOString()
    const mainDoctor = doctors[e.groupIndex]
    redirect(
      `${basePath}/create?dateFrom=${dateFrom}&mainDoctorId=${mainDoctor?.id}`,
    )
  }

  const handleEventClick = (e: any) => {
    e.cancel = true
    const recordId = e.event.Id
    redirect(`${basePath}/${recordId}`)
  }

  return !loading && doctorsLoaded ? (
    <ScheduleComponent
      height="550px"
      calendarMode="Gregorian"
      navigating={handleNavigating}
      cellClick={handleCellClick}
      cellDoubleClick={(e) => {
        e.cancel = true
      }}
      eventClick={handleEventClick}
      eventDoubleClick={(e) => {
        e.cancel = true
      }}
      allowMultiCellSelection={false}
      eventSettings={{ dataSource: getScheduleData }}
      selectedDate={
        filterValues.date && new Date(new Date(filterValues.date.split(';')[0]))
      }
    >
      <ResourcesDirective>
        <ResourceDirective
          field="DoctorId"
          title="Lekarz"
          name="Doctors"
          dataSource={doctorsResource}
          textField="text"
          idField="id"
        />
      </ResourcesDirective>
      <ViewsDirective>
        <ViewDirective
          timeFormat="dd.MM"
          option="TimelineMonth"
          orientation="Vertical"
          group={{ resources: ['Doctors'] }}
        />
      </ViewsDirective>
      <Inject services={[TimelineMonth]} />
    </ScheduleComponent>
  ) : (
    <></>
  )
}

export const VacationCalendarList = ({ ...props }: ListProps) => {
  const nowDate = new Date(Date.now())
  const { dateFrom, dateTo } = getFullMonthRangeFromDate(nowDate)
  return (
    <>
      <List
        filters={<VacationCalendarFilters />}
        filterDefaultValues={{
          date: `${dateFrom.toISOString()};${dateTo.toISOString()}`,
        }}
        {...props}
        pagination={false}
        exporter={false}
        sort={{ field: 'dateFrom', order: 'DESC' }}
      >
        <CustomMonthView />
      </List>
    </>
  )
}
