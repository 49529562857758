import {
  CreateParams,
  CreateResult,
  DataProvider,
  DeleteParams,
  DeleteResult,
  GetListResult,
  GetManyParams,
  GetManyResult,
  GetOneParams,
  GetOneResult,
  Identifier,
  UpdateParams,
  UpdateResult,
} from 'react-admin'
import { RADIOTHERAPY_AILMENTS_URL } from '../api-urls'
import {
  get,
  getWithPagination,
  patch,
  post,
  remove,
} from '../common/fetch.utils'
import {
  filterParamsComposer,
  queryParamsComposer,
} from '../common/get-by-conditions.utils'
import { RadOnkAPIGetListParams } from '../common/rad-onk-api-get-list.params'
import { AbdominalPainAilmentDTO } from '../dto/radiotherapy-ailment/abdominal-pain-ailment.dto'
import { AppetiteLossAilmentDTO } from '../dto/radiotherapy-ailment/appetite-loss-ailment.dto'
import { BloodStoolAilmentDTO } from '../dto/radiotherapy-ailment/blood-stool-ailment.dto'
import { BreathShortnessAilmentDTO } from '../dto/radiotherapy-ailment/breath-shortness-ailment.dto'
import { ConstipationAilmentDTO } from '../dto/radiotherapy-ailment/constipation-ailment.dto'
import { CoughAilmentDTO } from '../dto/radiotherapy-ailment/cough-ailment.dto'
import { DiarrheaAilmentDTO } from '../dto/radiotherapy-ailment/diarrhea-ailment.dto'
import { DryMouthAilmentDTO } from '../dto/radiotherapy-ailment/dry-mouth-ailment.dto'
import { FoodConsistencyChangeAilmentDTO } from '../dto/radiotherapy-ailment/food-consistency-change-ailment.dto'
import { HemoptysisAilmentDTO } from '../dto/radiotherapy-ailment/hemoptysis-ailment.dto'
import { NauseaAilmentDTO } from '../dto/radiotherapy-ailment/nausea-ailment.dto'
import { PainfulSwallowingAilmentDTO } from '../dto/radiotherapy-ailment/painfull-swallowing-ailment.dto'
import { RadiotherapyAilmentBaseDTO } from '../dto/radiotherapy-ailment/radiotherapy-ailment-base.dto'
import { SkinChangeAilmentDTO } from '../dto/radiotherapy-ailment/skin-change-ailment.dto'
import { UrinationDisorderAilmentDTO } from '../dto/radiotherapy-ailment/urination-disorder-ailment.dto'
import { VaginalMucusChangeAilmentDTO } from '../dto/radiotherapy-ailment/vaginal-mucus-change-ailment.dto'
import { VomitingAilmentDTO } from '../dto/radiotherapy-ailment/vomiting-ailment.dto'
import { WeaknessAilmentDTO } from '../dto/radiotherapy-ailment/weakness-ailment.dto'
import { RadiotherapyAilmentTypes } from '../enum/RadiotherapyAilmentTypes'
import filterMapper from './radiotherapy-ailments-filter.mapper'
import sortMapper from './radiotherapy-ailments-sort.mapper'

const provider = {
  getList: async (
    resource: string,
    { filter: { ...filter }, sort, pagination }: RadOnkAPIGetListParams,
  ): Promise<
    GetListResult<
      | AbdominalPainAilmentDTO
      | AppetiteLossAilmentDTO
      | BloodStoolAilmentDTO
      | ConstipationAilmentDTO
      | CoughAilmentDTO
      | DiarrheaAilmentDTO
      | DryMouthAilmentDTO
      | FoodConsistencyChangeAilmentDTO
      | WeaknessAilmentDTO
      | HemoptysisAilmentDTO
      | NauseaAilmentDTO
      | PainfulSwallowingAilmentDTO
      | SkinChangeAilmentDTO
      | UrinationDisorderAilmentDTO
      | VaginalMucusChangeAilmentDTO
      | VomitingAilmentDTO
      | BreathShortnessAilmentDTO
    >
  > => {
    const filterParams = `s=>${filterParamsComposer('s', filter, filterMapper)}`
    const pathParams = queryParamsComposer(sort, pagination, sortMapper)
    const path = `/${filterParams}?${pathParams ?? pathParams}`

    const {
      data,
      range: { total },
    } = await getWithPagination<RadiotherapyAilmentBaseDTO[]>(
      `${RADIOTHERAPY_AILMENTS_URL}/GetByConditions`,
      path,
    )

    return Promise.resolve({
      data,
      total,
    })
  },
  getOne: async (
    resource: string,
    { id }: GetOneParams,
  ): Promise<
    GetOneResult<
      | AbdominalPainAilmentDTO
      | AppetiteLossAilmentDTO
      | BloodStoolAilmentDTO
      | ConstipationAilmentDTO
      | CoughAilmentDTO
      | DiarrheaAilmentDTO
      | DryMouthAilmentDTO
      | FoodConsistencyChangeAilmentDTO
      | WeaknessAilmentDTO
      | HemoptysisAilmentDTO
      | NauseaAilmentDTO
      | PainfulSwallowingAilmentDTO
      | SkinChangeAilmentDTO
      | UrinationDisorderAilmentDTO
      | VaginalMucusChangeAilmentDTO
      | VomitingAilmentDTO
      | BreathShortnessAilmentDTO
    >
  > => {
    const data = await get<RadiotherapyAilmentBaseDTO>(
      `${RADIOTHERAPY_AILMENTS_URL}`,
      `/${id}`,
    )
    return Promise.resolve({
      data,
    })
  },
  getMany: async (
    resource: string,
    { ids }: GetManyParams,
  ): Promise<GetManyResult<RadiotherapyAilmentBaseDTO>> => {
    const data = await get<RadiotherapyAilmentBaseDTO[]>(
      `${RADIOTHERAPY_AILMENTS_URL}/GetByConditions`,
      `/a=>new int[] {${ids.toString()}}.Contains(a.Id)`,
    )
    return Promise.resolve({
      data,
    })
  },
  create: async (
    resource: string,
    { data }: CreateParams<CreateRadiotherapyAilmentRequest>,
  ): Promise<CreateResult> => {
    const created = await post<
      CreateRadiotherapyAilmentRequest[],
      RadiotherapyAilmentBaseDTO[]
    >(`${RADIOTHERAPY_AILMENTS_URL}`, [data])
    return Promise.resolve({ data: created[0] })
  },
  update: async (
    resource: string,
    { data }: UpdateParams<RadiotherapyAilmentBaseDTO>,
  ): Promise<UpdateResult> => {
    const update = await patch<
      { id: Identifier; [n: string]: any },
      RadiotherapyAilmentBaseDTO
    >(`${RADIOTHERAPY_AILMENTS_URL}`, data)
    return Promise.resolve({ data: update })
  },
  delete: async (
    resource: string,
    { id }: DeleteParams,
  ): Promise<DeleteResult> => {
    const data = await remove<RadiotherapyAilmentBaseDTO>(
      `${RADIOTHERAPY_AILMENTS_URL}`,
      `/${id}`,
    )
    return {
      data: {
        id: data.id,
      },
    }
  },
} as DataProvider

export interface CreateRadiotherapyAilmentRequest {
  readonly ailmentType?: RadiotherapyAilmentTypes
  readonly occurs?: boolean
  readonly ailmentSurveyId?: number
  readonly level?: number
  readonly foodHabitsChange?: boolean
  readonly difficult?: boolean
  readonly pain?: boolean
  readonly moreFrequent?: boolean
  readonly immediateNeed?: boolean
  readonly difficultyHolding?: boolean
  readonly moreDense?: boolean
  readonly blood?: boolean
  readonly largeAmoundOfFreshBlood?: boolean
  readonly weightLoss?: boolean
}

export default provider
