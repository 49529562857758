import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  FunctionField,
  NumberInput,
  ReferenceField,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useTranslate,
} from 'react-admin'
import { validatePolish } from 'validate-polish'
import { PatientDto } from '../../core/dto/user/patient.dto'
import { PatientTags } from '../../core/enum/PatientTags'
import { ResourceName } from '../../core/ResourceName'
import FilteredReferenceInput from '../common/FilteredReferenceInput'
import { DoctorDto } from '../../core/dto/user/doctor.dto'
import { useHasAuthority } from '../hooks/useHasAuthority'
import { Authority } from '../../core/auth/Authority'

interface PatientTitleProps extends TitleProps {
  record?: PatientDto
}

export const validatePatientEdit = (values: PatientDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required'
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required'
  }
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  if (!values.pesel) {
    errors.pesel = 'ra.validation.required'
  }
  if (!values.patientRadiotherapySurveyInterval) {
    errors.patientRadiotherapySurveyInterval = 'ra.validation.required'
  }
  if (
    values.patientRadiotherapySurveyInterval < 7 ||
    values.patientRadiotherapySurveyInterval > 90
  ) {
    errors.patientRadiotherapySurveyInterval = 'ra.validation.out_of_range'
  }
  if (!values.patientChemotherapySurveyInterval) {
    errors.patientChemotherapySurveyInterval = 'ra.validation.required'
  }
  if (
    values.patientChemotherapySurveyInterval < 7 ||
    values.patientChemotherapySurveyInterval > 90
  ) {
    errors.patientChemotherapySurveyInterval = 'ra.validation.out_of_range'
  }
  if (!values.pesel || !validatePolish.pesel(values.pesel)) {
    errors.pesel = 'ra.validation.incorrect_pesel'
  }
  return errors
}

const EditTitle = (props: PatientTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.patients.titles.edit')}: {record?.firstName}{' '}
      {record?.lastName}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const PatientEditForm = ({ ...props }) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const hasAuthority = useHasAuthority()
  const translate = useTranslate()

  return (
    <SimpleForm
      {...props}
      redirect="show"
      validate={validatePatientEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      <FunctionField<PatientDto>
        source="active"
        render={(record?: PatientDto) => (
          <div>
            {record?.active === true
              ? translate('const.yes')
              : translate('const.no')}
          </div>
        )}
      />
      <TextInput source="firstName" isRequired />
      <TextInput source="lastName" isRequired />
      <TextInput source="pesel" isRequired />
      <TextInput source="userName" isRequired />
      {hasAuthority(Authority.WEB_USER) ? (
        <FilteredReferenceInput
          source="mainDoctorId"
          defaultValue={null}
          reference={ResourceName.DOCTORS}
          sort={{ field: 'lastName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="fullNameSearch"
          filter={{ doctorsOnly: true }}
          emptyText="-"
          selectWithPaginationInputProps={{
            optionText: (record: DoctorDto) =>
              `${record.firstName} ${record.lastName}`,
            showFilter: true,
          }}
        />
      ) : (
        <ReferenceField
          source="mainDoctorId"
          reference={ResourceName.DOCTORS}
          link="show"
          emptyText="-"
        >
          <FunctionField<DoctorDto>
            source="mainDoctorId"
            render={(record?: DoctorDto) => (
              <Typography variant="body2">
                {record?.firstName} {record?.lastName}
              </Typography>
            )}
          />
        </ReferenceField>
      )}
      <ReferenceField
        source="temporaryDoctorId"
        reference={ResourceName.DOCTORS}
        link="show"
        emptyText="-"
      >
        <FunctionField<DoctorDto>
          source="temporaryDoctorId"
          render={(record?: DoctorDto) => (
            <Typography variant="body2">
              {record?.firstName} {record?.lastName}
            </Typography>
          )}
        />
      </ReferenceField>
      <BooleanInput source="isRadiotherapyModuleAvailable" />
      <BooleanInput source="isRadiochemotherapyModuleAvailable" />
      <BooleanInput source="isChemotherapyModuleAvailable" />
      <BooleanInput source="isBrachytherapyModuleAvailable" />
      <SelectInput
        source="tag"
        choices={[
          {
            id: null,
            name: 'const.emptySelection',
          },
          ...Object.entries(PatientTags).map(([value]) => ({
            id: value,
            name: `resources.enums.patientTags.${value}`,
          })),
        ]}
      />
      <NumberInput
        source="patientRadiotherapySurveyInterval"
        min={7}
        max={90}
      />
      <NumberInput
        source="patientChemotherapySurveyInterval"
        min={7}
        max={90}
      />
      <TextInput source="diagnosis" multiline />
    </SimpleForm>
  )
}

export const PatientEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <PatientEditForm />
  </Edit>
)
