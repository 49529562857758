import {
  Card,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Slider,
} from '@material-ui/core'
import { lightBlue } from '@material-ui/core/colors'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { ListProps, useListContext, useTranslate } from 'react-admin'
import { AppetiteLossAilmentDTO } from '../../../core/dto/radiotherapy-ailment/appetite-loss-ailment.dto'
import { BreathShortnessAilmentDTO } from '../../../core/dto/radiotherapy-ailment/breath-shortness-ailment.dto'
import { ConstipationAilmentDTO } from '../../../core/dto/radiotherapy-ailment/constipation-ailment.dto'
import { CoughAilmentDTO } from '../../../core/dto/radiotherapy-ailment/cough-ailment.dto'
import { FoodConsistencyChangeAilmentDTO } from '../../../core/dto/radiotherapy-ailment/food-consistency-change-ailment.dto'
import { NauseaAilmentDTO } from '../../../core/dto/radiotherapy-ailment/nausea-ailment.dto'
import { PainfulSwallowingAilmentDTO } from '../../../core/dto/radiotherapy-ailment/painfull-swallowing-ailment.dto'
import { RadiotherapyAilmentBaseDTO } from '../../../core/dto/radiotherapy-ailment/radiotherapy-ailment-base.dto'
import { SkinChangeAilmentDTO } from '../../../core/dto/radiotherapy-ailment/skin-change-ailment.dto'
import { UrinationDisorderAilmentDTO } from '../../../core/dto/radiotherapy-ailment/urination-disorder-ailment.dto'
import { VaginalMucusChangeAilmentDTO } from '../../../core/dto/radiotherapy-ailment/vaginal-mucus-change-ailment.dto'
import { VomitingAilmentDTO } from '../../../core/dto/radiotherapy-ailment/vomiting-ailment.dto'
import { WeaknessAilmentDTO } from '../../../core/dto/radiotherapy-ailment/weakness-ailment.dto'
import List from '../../common/customized-ra-components/List'

const useStyles = makeStyles((theme) => ({
  cardStyle: {
    margin: '0.5em',
    verticalAlign: 'top',
    '& .MuiCardContent-root': {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  slider: {
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      marginTop: -9,
      marginLeft: -11,
    },
    '& .MuiSlider-track': {
      height: 6,
      backgroundColor: theme.palette.error.main,
    },
    '& .MuiSlider-rail': {
      height: 6,
      opacity: 0.5,
      backgroundColor: lightBlue.A700,
    },
    '& .MuiSlider-mark': {
      height: 20,
      width: 2,
      backgroundColor: theme.palette.grey[500],
      marginTop: -7,
    },
  },
}))

const availableSliderMarks = [
  {
    value: 1,
    label: <Typography>1</Typography>,
  },
  {
    value: 2,
    label: <Typography>2</Typography>,
  },
  {
    value: 3,
    label: <Typography>3</Typography>,
  },
  {
    value: 4,
    label: <Typography>4</Typography>,
  },
  {
    value: 5,
    label: <Typography>5</Typography>,
  },
  {
    value: 6,
    label: <Typography>6</Typography>,
  },
  {
    value: 7,
    label: <Typography>7</Typography>,
  },
  {
    value: 8,
    label: <Typography>8</Typography>,
  },
  {
    value: 9,
    label: <Typography>9</Typography>,
  },
  {
    value: 10,
    label: <Typography>10</Typography>,
  },
]

const convertLevelEnumToNumber = (level: string) =>
  Number(level ? level?.toString().replace('_', '') : '1')

const RadiotherapyAilmentsGrid = () => {
  const { ids, data } = useListContext()
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div style={{ margin: '1em' }}>
      {ids.map((id) => (
        <Card key={id} className={classes.cardStyle}>
          <CardHeader
            titleTypographyProps={{ variant: 'h6' }}
            style={{
              textDecoration:
                data[id]?.occurs === true ? 'underline' : 'line-through',
              color: data[id]?.occurs === true ? undefined : 'gray',
            }}
            title={translate(
              `resources.enums.radiotherapyAilmentTypes.${data[id]?.ailmentType}`,
            )}
          />
          {(data[id] as RadiotherapyAilmentBaseDTO).occurs
            ? {
                SKIN_CHANGE: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={4}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.skinChange._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.skinChange._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.skinChange._3',
                            ),
                            _4: translate(
                              'resources.radiotherapy-ailments.fields.skinChange._4',
                            ),
                          }[(data[id] as SkinChangeAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                DRY_MOUTH: undefined,
                PAINFUL_SWALLOWING: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.painfullSwallowing._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.painfullSwallowing._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.painfullSwallowing._3',
                            ),
                          }[(data[id] as PainfulSwallowingAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                NAUSEA: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.nausea._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.nausea._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.nausea._3',
                            ),
                          }[(data[id] as NauseaAilmentDTO)?.level] || undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                VOMITING: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.vomiting._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.vomiting._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.vomiting._3',
                            ),
                          }[(data[id] as VomitingAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                APPETITE_LOSS: (
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.foodHabitsChange',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as AppetiteLossAilmentDTO)
                              ?.foodHabitsChange
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.weightLoss',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as AppetiteLossAilmentDTO)?.weightLoss
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                ),
                FOOD_CONSISTENCY_CHANGE: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.foodConsistencyChange._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.foodConsistencyChange._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.foodConsistencyChange._3',
                            ),
                          }[
                            (data[id] as FoodConsistencyChangeAilmentDTO)?.level
                          ] || undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                WEAKNESS: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.weakness._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.weakness._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.weakness._3',
                            ),
                          }[(data[id] as WeaknessAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                COUGH: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={2}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks.map((mark) => {
                        if (mark.value === 1)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>1</Typography>
                                <Typography style={{ marginLeft: 160 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.cough._1',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        if (mark.value === 2)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>2</Typography>
                                <Typography style={{ marginLeft: -140 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.cough._2',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        return mark
                      })}
                      className={classes.slider}
                      style={{ marginBottom: 80 }}
                      disabled
                    />
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.difficult',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={(data[id] as CoughAilmentDTO)?.difficult}
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.cough.pain',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={(data[id] as CoughAilmentDTO)?.pain}
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                ),
                HEMOPTYSIS: undefined,
                DIARRHEA: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks.map((mark) => {
                        if (mark.value === 1)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography style={{ marginLeft: 25 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.diarrhea._1',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        if (mark.value === 2)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.diarrhea._2',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        if (mark.value === 3)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography style={{ marginLeft: -50 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.diarrhea._3',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        return mark
                      })}
                      className={classes.slider}
                      style={{ marginBottom: 20 }}
                      disabled
                    />
                  </CardContent>
                ),
                CONSTIPATION: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={2}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.constipation._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.constipation._1',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.constipation._2',
                            ),
                          }[(data[id] as ConstipationAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
                BLOOD_STOOL: undefined,
                URINATION_DISORDER: (
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.urinationDisorder.pain',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as UrinationDisorderAilmentDTO)?.pain
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.urinationDisorder.moreFrequent',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as UrinationDisorderAilmentDTO)
                              ?.moreFrequent
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.urinationDisorder.immediateNeed',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as UrinationDisorderAilmentDTO)
                              ?.immediateNeed
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.urinationDisorder.difficultyHolding',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as UrinationDisorderAilmentDTO)
                              ?.difficultyHolding
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                ),
                ABDOMINAL_PAIN: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={10}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks.map((mark) => {
                        if (mark.value === 1)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>1</Typography>
                                <Typography style={{ marginLeft: 10 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._1-line1',
                                  )}
                                </Typography>
                                <Typography style={{ marginLeft: 10 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._1-line2',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        if (mark.value === 5)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>5</Typography>
                                <Typography>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._5-line1',
                                  )}
                                </Typography>
                                <Typography>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._5-line2',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        if (mark.value === 10)
                          return {
                            ...mark,
                            label: (
                              <div style={{ textAlign: 'center' }}>
                                <Typography>10</Typography>
                                <Typography style={{ marginRight: 60 }}>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._10-line1',
                                  )}
                                </Typography>
                                <Typography>
                                  {translate(
                                    'resources.radiotherapy-ailments.fields.abdominalPain._10-line2',
                                  )}
                                </Typography>
                              </div>
                            ),
                          }
                        return mark
                      })}
                      className={classes.slider}
                      style={{ paddingBottom: 50 }}
                      disabled
                    />
                  </CardContent>
                ),
                VAGINAL_MUCUS_CHANGE: (
                  <CardContent>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.vaginalMucusChange.moreDense',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as VaginalMucusChangeAilmentDTO)
                              ?.moreDense
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.vaginalMucusChange.blood',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as VaginalMucusChangeAilmentDTO)?.blood
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography>
                          {translate(
                            'resources.radiotherapy-ailments.fields.vaginalMucusChange.largeAmoundOfFreshBlood',
                          )}
                          :
                        </Typography>
                      </Grid>
                      <Grid item>
                        <RadioGroup
                          row
                          value={
                            (data[id] as VaginalMucusChangeAilmentDTO)
                              ?.largeAmoundOfFreshBlood
                          }
                        >
                          <FormControlLabel
                            // eslint-disable-next-line react/jsx-boolean-value
                            value={true}
                            control={<Radio color="primary" />}
                            label={translate('const.yes')}
                          />
                          <FormControlLabel
                            value={false}
                            control={<Radio color="primary" />}
                            label={translate('const.no')}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </CardContent>
                ),
                BREATH_SHORTNESS: (
                  <CardContent>
                    <Slider
                      min={1}
                      max={3}
                      defaultValue={convertLevelEnumToNumber(data[id]?.level)}
                      marks={availableSliderMarks}
                      className={classes.slider}
                      disabled
                    />
                    <Card>
                      <CardContent>
                        <Typography>
                          {{
                            _1: translate(
                              'resources.radiotherapy-ailments.fields.breathShortness._1',
                            ),
                            _2: translate(
                              'resources.radiotherapy-ailments.fields.breathShortness._2',
                            ),
                            _3: translate(
                              'resources.radiotherapy-ailments.fields.breathShortness._3',
                            ),
                          }[(data[id] as BreathShortnessAilmentDTO)?.level] ||
                            undefined}
                        </Typography>
                      </CardContent>
                    </Card>
                  </CardContent>
                ),
              }[(data[id] as RadiotherapyAilmentBaseDTO).ailmentType] ||
              undefined
            : undefined}
          {/* <CardActions style={{ textAlign: 'right' }}>
            <EditButton resource="posts" basePath={basePath} record={data[id]} />
          </CardActions> */}
        </Card>
      ))}
    </div>
  )
}

export const RadiotherapyAilmentList = ({ ...props }: ListProps) => (
  <List
    {...props}
    perPage={20}
    pagination={false}
    exporter={false}
    sort={{ field: 'occurs', order: 'DESC' }}
    actions={false}
  >
    <RadiotherapyAilmentsGrid />
  </List>
)
