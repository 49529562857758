import { useMediaQuery, useTheme } from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import React from 'react'
import {
  BooleanInput,
  Edit,
  EditProps,
  FunctionField,
  NumberInput,
  SaveButton,
  SelectInput,
  ShowButton,
  SimpleForm,
  TextField,
  TextInput,
  TitleProps,
  Toolbar,
  ToolbarProps,
  useEditContext,
  useTranslate,
} from 'react-admin'
import { validatePolish } from 'validate-polish'
import { PatientDto } from '../../../core/dto/user/patient.dto'
import { UserBaseDto } from '../../../core/dto/user/user-base.dto'
import { PatientTags } from '../../../core/enum/PatientTags'
import { UserTypes } from '../../../core/enum/UserTypes'
import { ResourceName } from '../../../core/ResourceName'
import Base64ImageInput from '../../common/Base64ImageInput'
import FilteredReferenceInput from '../../common/FilteredReferenceInput'

interface UserTitleProps extends TitleProps {
  record?: UserBaseDto
}

export const validateUserEdit = (values: UserBaseDto) => {
  const errors: { [n: string]: string } = {}
  if (!values.firstName) {
    errors.firstName = 'ra.validation.required'
  }
  if (!values.lastName) {
    errors.lastName = 'ra.validation.required'
  }
  if (!values.userName) {
    errors.userName = 'ra.validation.required'
  }
  if (
    values.userType === UserTypes.PATIENT &&
    (!(values as PatientDto).pesel ||
      !validatePolish.pesel((values as PatientDto).pesel))
  ) {
    errors.pesel = 'ra.validation.incorrect_pesel'
  }
  return errors
}

const EditTitle = (props: UserTitleProps) => {
  const { record } = props
  const translate = useTranslate()
  return (
    <span>
      {translate('resources.users.titles.edit')}: {record?.id}.{' '}
      {record?.userName}{' '}
    </span>
  )
}

const EditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
    <ShowButton icon={<Cancel />} label="const.cancel" />
  </Toolbar>
)

const UserEditForm = ({ ...props }) => {
  const { record: user } = useEditContext()
  const translate = useTranslate()
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <SimpleForm
      {...props}
      redirect="show"
      validate={validateUserEdit}
      toolbar={<EditToolbar />}
    >
      <TextField source="id" label="ID" />
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <FunctionField<PatientDto>
          source="active"
          render={(record?: PatientDto) => (
            <div>
              {record?.active === true
                ? translate('const.yes')
                : translate('const.no')}
            </div>
          )}
        />
      )}
      <TextInput source="firstName" isRequired />
      <TextInput source="lastName" isRequired />
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <TextInput source="pesel" isRequired />
      )}
      <TextInput source="userName" isRequired />
      {((user as UserBaseDto)?.userType === UserTypes.DOCTOR ||
        (user as UserBaseDto)?.userType === UserTypes.WEB_USER) && (
        <TextInput source="email" />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.DOCTOR && (
        <Base64ImageInput
          source="base64Photo"
          outputSource="blobPhoto"
          label="resources.users.fields.base64Photo"
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <FilteredReferenceInput
          source="mainDoctorId"
          defaultValue={null}
          reference={ResourceName.USERS}
          sort={{ field: 'userName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="userName"
          filter={{ doctorsOnly: true }}
          emptyText="-"
          selectWithPaginationInputProps={{
            optionText: 'userName',
            showFilter: true,
          }}
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <FilteredReferenceInput
          source="temporaryDoctorId"
          defaultValue={null}
          reference={ResourceName.USERS}
          sort={{ field: 'userName', order: 'ASC' }}
          perPage={smallScreen ? 5 : 15}
          allowEmpty
          filterSource="userName"
          filter={{ doctorsOnly: true }}
          emptyText="-"
          selectWithPaginationInputProps={{
            optionText: 'userName',
            showFilter: true,
          }}
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <BooleanInput source="isRadiotherapyModuleAvailable" />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <BooleanInput source="isRadiochemotherapyModuleAvailable" />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <BooleanInput source="isChemotherapyModuleAvailable" />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <BooleanInput source="isBrachytherapyModuleAvailable" />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <SelectInput
          source="tag"
          choices={[
            {
              id: null,
              name: 'const.emptySelection',
            },
            ...Object.entries(PatientTags).map(([value]) => ({
              id: value,
              name: `resources.enums.patientTags.${value}`,
            })),
          ]}
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <NumberInput
          source="patientRadiotherapySurveyInterval"
          isRequired
          min={7}
          max={90}
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <NumberInput
          source="patientChemotherapySurveyInterval"
          isRequired
          min={7}
          max={90}
        />
      )}
      {(user as UserBaseDto)?.userType === UserTypes.PATIENT && (
        <TextInput source="diagnosis" multiline />
      )}
      <FunctionField<UserBaseDto>
        source="userType"
        sortable={false}
        render={(record?: UserBaseDto) => (
          <div>
            {translate(`resources.enums.userTypes.${record?.userType}`)}
          </div>
        )}
      />
      {/* {((user as UserBaseDto)?.userType === UserTypes.DOCTOR ||
        (user as UserBaseDto)?.userType === UserTypes.WEB_USER) && (
        <BooleanInput source="requirePasswordUpdate" />
      )} */}
    </SimpleForm>
  )
}

export const UserEdit = (props: EditProps) => (
  <Edit {...props} title={<EditTitle />} actions={false}>
    <UserEditForm />
  </Edit>
)
